<template>

  <a-modal
    title="修改头像"
    :visible="visible"
    :maskClosable="false"
    :confirmLoading="confirmLoading"
    :width="800"
    :footer="null"
    @cancel="cancelHandel">
    <a-row>
      <a-col :xs="24" :md="12" :style="{height: '350px'}">
        <vue-cropper
          ref="cropper"
          :img="options.img"
          :info="true"
          :autoCrop="options.autoCrop"
          :autoCropWidth="options.autoCropWidth"
          :autoCropHeight="options.autoCropHeight"
          :fixedBox="options.fixedBox"
          @realTime="realTime"
        >
        </vue-cropper>
      </a-col>
      <a-col :xs="24" :md="12" :style="{height: '350px'}">
        <div class="avatar-upload-preview">
          <img :src="previews.url" :style="previews.img"/>
        </div>
      </a-col>
    </a-row>
    <br>
    <a-row>
      <a-col :lg="2" :md="2">
        <a-upload name="file" :beforeUpload="beforeUpload" :showUploadList="false">
          <a-button icon="upload">选择图片</a-button>
        </a-upload>
      </a-col>
      <a-col :lg="{span: 1, offset: 2}" :md="2">
        <a-button icon="plus" @click="changeScale(1)"/>
      </a-col>
      <a-col :lg="{span: 1, offset: 1}" :md="2">
        <a-button icon="minus" @click="changeScale(-1)"/>
      </a-col>
      <a-col :lg="{span: 1, offset: 1}" :md="2">
        <a-button icon="undo" @click="rotateLeft"/>
      </a-col>
      <a-col :lg="{span: 1, offset: 1}" :md="2">
        <a-button icon="redo" @click="rotateRight"/>
      </a-col>
      <a-col :lg="{span: 2, offset: 6}" :md="2">
        <a-button type="primary" @click="finish('blob')">保存</a-button>
      </a-col>
    </a-row>
  </a-modal>

</template>
<script>
import { getToken } from '@/api/upload'
import OSS from 'ali-oss'

export default {
  data () {
    return {
      visible: false,
      id: null,
      confirmLoading: false,
      fileList: [],
      uploading: false,
      options: {
        // img: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
        img: '',
        autoCrop: true,
        autoCropWidth: 200,
        autoCropHeight: 200,
        fixedBox: true
      },
      previews: {},
      tokenInfo: {
        'region': '',
        'AccessKeyId': '',
        'AccessKeySecret': '',
        'SecurityToken': '',
        'bucket': '',
        'domain': ''
      }
    }
  },
  created () {
    this.getTokenApi()
  },
  methods: {
    edit (id, url) {
      this.options.img = url
      this.visible = true
      this.id = id
      /* 获取原始头像 */
    },
    close () {
      this.id = null
      this.visible = false
    },
    cancelHandel () {
      this.close()
    },
    changeScale (num) {
      num = num || 1
      this.$refs.cropper.changeScale(num)
    },
    rotateLeft () {
      this.$refs.cropper.rotateLeft()
    },
    rotateRight () {
      this.$refs.cropper.rotateRight()
    },
    beforeUpload (file) {
      const reader = new FileReader()
      // 把Array Buffer转化为blob 如果是base64不需要
      // 转化为base64
      reader.readAsDataURL(file)
      reader.onload = () => {
        this.options.img = reader.result
      }
      // 转化为blob
      // reader.readAsArrayBuffer(file)

      return false
    },

    // 上传图片（点击上传按钮）
    finish (type) {
      console.log('finish', type)
      const _this = this
      const formData = new FormData()
      const url = this.getImageName()
      console.log(url)
      // 输出
      if (type === 'blob') {
        this.$refs.cropper.getCropBlob(async (data) => {
          const img = window.URL.createObjectURL(data)
          this.model = true
          this.modelSrc = img
          console.log('fileName', img, this.fileName)
          formData.append('file', data, this.fileName)
          const client = new OSS({
            // yourRegion填写Bucket所在地域。以华东1（杭州）为例，Region填写为oss-cn-hangzhou。
            region: _this.tokenInfo.region,
            // 阿里云账号AccessKey拥有所有API的访问权限，风险很高。强烈建议您创建并使用RAM用户进行API访问或日常运维，请登录RAM控制台创建RAM用户。
            accessKeyId: _this.tokenInfo.AccessKeyId,
            accessKeySecret: _this.tokenInfo.AccessKeySecret,
            stsToken: _this.tokenInfo.SecurityToken,
            // 填写Bucket名称，例如examplebucket。
            bucket: _this.tokenInfo.bucket,
            secure: true
          })

          const headers = {
            // 指定该Object被下载时的网页缓存行为。
            'Cache-Control': 'no-cache',
            // 指定该Object被下载时的名称。
            'Content-Disposition': url,
            // 指定该Object被下载时的内容编码格式。
            'Expires': 1000,
            'Content-Encoding': 'utf-8',
            // 指定Object标签，可同时设置多个标签。
            'x-oss-tagging': 'image=1',
            // 指定初始化分片上传时是否覆盖同名Object。此处设置为true，表示禁止覆盖同名Object。
            'x-oss-forbid-overwrite': 'true'
          }

          const options = {
            // 获取分片上传进度、断点和返回值。
            progress: (p, cpt, res) => {
              console.log(p)
            },
            // 设置并发上传的分片数量。
            parallel: 4,
            // 设置分片大小。默认值为1 MB，最小值为100 KB。
            partSize: 1024 * 1024,
            headers
          }
          console.log(client, options)

          try {
            const result = await client.multipartUpload(url, data, {
              ...options
            })

            if (result.name) {
              console.log(_this.tokenInfo.domain + result.name)
              _this.$message.success('上传成功')
              _this.$emit('ok', _this.tokenInfo.domain + result.name)
              _this.visible = false
            } else {
              _this.$message.error('上传失败')
            }
            console.log(result)
          } catch (e) {
            console.log(e)
          }

          /* this.$http.post('https://www.mocky.io/v2/5cc8019d300000980a055e76', formData, { contentType: false, processData: false, headers: { 'Content-Type': 'application/x-www-form-urlencoded' } })
            .then((response) => {
              console.log('upload response:', response)
              // var res = response.data
              // if (response.status === 'done') {
              //   _this.imgFile = ''
              //   _this.headImg = res.realPathList[0] // 完整路径
              //   _this.uploadImgRelaPath = res.relaPathList[0] // 非完整路径
              //   _this.$message.success('上传成功')
              //   this.visible = false
              // }
              _this.$message.success('上传成功')
              _this.$emit('ok', response.url)
              _this.visible = false
            }) */
        })
      } else {
        this.$refs.cropper.getCropData((data) => {
          this.model = true
          this.modelSrc = data
        })
      }
    },
    okHandel () {
      const vm = this

      vm.confirmLoading = true
      setTimeout(() => {
        vm.confirmLoading = false
        vm.close()
        vm.$message.success('上传头像成功')
      }, 2000)
    },

    realTime (data) {
      this.previews = data
    },
    getRandomStr (len) {
      var str = ''
      for (var i = 0; i < len; i++) {
        str += Math.random().toString(36).substr(2)
      }
      return str.substr(0, len)
    },
    getImageName () {
      var myDate = new Date()
      var year = myDate.getFullYear()
      var month = myDate.getMonth() + 1
      var day = myDate.getDate()
      if (month >= 1 && month <= 9) {
        month = '0' + month
      }

      if (day >= 0 && day <= 9) {
        day = '0' + day
      }
      var randomStr = this.getRandomStr(14)
      return 'image/' + year + '/' + month + day + '/' + randomStr + '.png'
    },
    getTokenApi () {
      const _this = this
      getToken().then((res) => {
        if (res.errorCode === 0) {
          _this.tokenInfo = res.result
          console.log('tokenInfo', _this.tokenInfo)
        } else {
          _this.$message.error(res.errorMsg)
        }
      }).catch((err) => {
        _this.$message.error('系统错误')
        console.log(err)
      })
    }
  }
}
</script>

<style lang="less" scoped>

  .avatar-upload-preview {
    position: absolute;
    top: 50%;
    transform: translate(50%, -50%);
    width: 180px;
    height: 180px;
    border-radius: 50%;
    box-shadow: 0 0 4px #ccc;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
    }
  }
</style>
